<template>
  <div>
    <px-capa :banner-capa="header" class="white--text">
      <template v-slot:title>
        Compre produtos para sua empresa com a Proxpect
      </template>
      <template v-slot:text>
        Cadastre-se e compre agora, sem precisar de cotações
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        :show-price="false"
        link="/fornecedor/5557"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12" phone-number="5548992120020"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import gridMixin from '@/mixins/grid-mixin';
import RdDuvidaFormSubscription from '../../integration/rd/RdDuvidaFormSubscription.vue';
import LandingPageWhatsapp from '../LandingPageWhatsapp.vue';
import header from '../../../assets/landing-page/genova/banner desk.png';

import cavalete from '../../../assets/landing-page/genova/cavalete de carga -desk.png';
import cavaleteMobile from '../../../assets/landing-page/genova/cavalete de carga - mobbile.png';

import cavaleteAuto from '../../../assets/landing-page/genova/CAVALETES AUTOALINHANTES - DESK.png';
import cavaleteAutoMobile from '../../../assets/landing-page/genova/CAVALETES AUTOALINHANTES -mobile.png';

import rolo from '../../../assets/landing-page/genova/ROLO DE IMPACTO - DESK.png';
import roloMobile from '../../../assets/landing-page/genova/ROLO DE IMPACTO - mobbile.png';

import rolete from '../../../assets/landing-page/genova/ROlete de carga - desk.png';
import roleteMobile from '../../../assets/landing-page/genova/ROlete de carga - mobbile.png';

import tambor from '../../../assets/landing-page/genova/TAMBOREs - DESK.png';
import tamborMobile from '../../../assets/landing-page/genova/TAMBOREs -mobile.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      header,
      query: 'disponivel is true and fornecedor.id = 5557 order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os produtos que sua empresa precisa',
      banners: [],
    };
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(cavalete, cavaleteMobile),
        link: '/oferta/144940',
      },
      {
        foto: this.getGridImg(cavaleteAuto, cavaleteAutoMobile),
        link: '/oferta/144943',
      },
      {
        foto: this.getGridImg(rolo, roloMobile),
        link: '/oferta/144932',
      },
      {
        foto: this.getGridImg(rolete, roleteMobile),
        link: '/oferta/144933',
      },
      {
        foto: this.getGridImg(tambor, tamborMobile),
        link: '/oferta/144938',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxLandingPageBanners: LandingPageBanners,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    pxComoFunciona: ComoFunciona,
    pxVantagens: Vantagens,
    pxCapa: Capa,
    pxFaqLandingPage: FaqLandingPage,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
